import { callAPI, mainAPIURL } from 'api/main';

interface ICheckIfUserExistsProps {
  document: string;
}

export default async ({ document }: ICheckIfUserExistsProps): Promise<string | null> => {
  try {
    const res = await callAPI({
      callId: 'checkIfUserExists',
      path: `${mainAPIURL}/v2/person/check-exists`,
      method: 'POST',
      params: {
        Document: document,
      },
    });

    if (res && res.request.Id) {
      return res.request.Id;
    }

    return null;
  } catch (ex) {
    return null;
  }
};
