import styled, { css } from 'styled-components';

interface IContainerProps {
  $error?: string;
  $height?: number;
}

export const Container = styled.label<IContainerProps>`
  margin-bottom: 0.9375rem;
  display: block;
  position: relative;

  textarea,
  textarea:hover,
  textarea:active,
  textarea:focus {
    display: block;
    width: 100%;

    background-color: ${({ theme }) => theme.colors.white};

    box-sizing: border-box;
    border: 1px solid
      ${({ $error, theme }) => ($error ? theme.colors.danger.color : theme.colors.border)} !important;
    box-shadow: inset -3px 3px 0px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    font-size: 0.9rem;
    padding: 0.97rem 0.9rem;
    color: ${({ theme }) => theme.colors.paragraph};

    height: ${({ $height }) => $height || 100}%;

    &:hover {
      border: 0.0625rem solid
        ${({ $error, theme }) => ($error ? theme.colors.danger.color : theme.colors.paragraph)};
    }

    &::placeholder {
      font-family: 'Raleway', sans-serif;
    }

    @media (max-width: 991px) {
      font-size: 1rem;
    }
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.danger.color} !important;
  font-size: 0.7rem !important;
  font-weight: bold;
  padding: 0.3125rem;
  display: block;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.paragraph};
`;

interface IPasswordIconContainerType {
  $hasLabel: boolean;
}
export const PasswordIconContainer = styled.button<IPasswordIconContainerType>`
  position: absolute;
  right: 0;
  top: 0;
  min-width: 1px !important;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.25rem;

  svg {
    color: ${({ theme }) => theme.colors.blue};
    width: 0.9375rem !important;

    ${({ $hasLabel }) =>
      $hasLabel &&
      css`
        margin-top: 1.25rem;
      `}
  }
`;

export const IconContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.25rem;

    svg {
      color: ${({ theme }) => theme.colors.blue};
      width: 0.9375rem !important;
    }
  }

  input {
    padding-left: 3.125rem;
  }
`;

interface IMaxCharsContainer {
  $reachedMaxChars: boolean;
}
export const MaxCharsContainer = styled.p<IMaxCharsContainer>`
  text-align: right;

  ${({ $reachedMaxChars }) =>
    $reachedMaxChars &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.colors.danger.color};
    `}
`;
