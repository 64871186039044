import { useMemo } from 'react';

import { useTranslation } from 'next-i18next';

import Drawer from 'components/atom/Drawer';

import { DefaultContainer } from '../_common/styles';
import { useFormController } from '../../useFormController';
import AskForCode from './Steps/AskForCode';
import InsertCode from './Steps/InsertCode';
import InsertPassword from './Steps/InsertPassword';
import {
  UseRecoverPasswordControllerProvider,
  useRecoverPasswordController,
} from './useRecoverPasswordController';

const RecoverPassword: React.FC = () => {
  const { t } = useTranslation();
  const { isRecoverPasswordOpen, setRecoverPasswordOpen } = useFormController();
  const { actualStep } = useRecoverPasswordController();

  const StepComponent = useMemo(() => {
    const possibilities = {
      'ask-code': AskForCode,
      'insert-code': InsertCode,
      'insert-password': InsertPassword,
    };

    return possibilities[actualStep];
  }, [actualStep]);

  return (
    <Drawer
      isOpen={isRecoverPasswordOpen}
      onClose={() => setRecoverPasswordOpen(false)}
      size="full"
      title={t('auth-login:form:drawers:recover-password:title')}
      placement="top"
    >
      <DefaultContainer>
        <StepComponent />
      </DefaultContainer>
    </Drawer>
  );
};

const RecoverPasswordWrapper: React.FC = () => (
  <UseRecoverPasswordControllerProvider>
    <RecoverPassword />
  </UseRecoverPasswordControllerProvider>
);
export default RecoverPasswordWrapper;
