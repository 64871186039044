import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';

import { useTranslation } from 'next-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';

import { useRecoverPasswordController } from '../../useRecoverPasswordController';
import schema from './schema';
import { IInsertPasswordFormProps } from './types';

const InsertPassword: React.FC = () => {
  const { t } = useTranslation();
  const { defineNewPassword, isLoading } = useRecoverPasswordController();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IInsertPasswordFormProps>({
    resolver: yupResolver(schema()),
  });

  const watchPassword = watch('password');

  const passwordStrengthStrings = useMemo(
    () => [
      t('common:bad'),
      t('common:weak'),
      t('common:good'),
      t('common:great'),
      t('common:excellent'),
    ],
    [t],
  );

  return (
    <form onSubmit={handleSubmit((data) => defineNewPassword(data.password))}>
      <section>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              label={t('auth-login:form:drawers:registration:choose-password')}
              error={errors.password?.message}
            />
          )}
        />
        <PasswordStrengthBar
          password={watchPassword}
          scoreWords={passwordStrengthStrings}
          shortScoreWord={t('common:too-short')}
          onChangeScore={(score) => setValue('passwordStrength', score, { shouldValidate: true })}
          className="password-strength-bar"
        />

        <Controller
          name="passwordConfirm"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              label={t('auth-login:form:drawers:registration:confirm-password')}
              error={errors.passwordConfirm?.message}
            />
          )}
        />
      </section>

      <footer>
        <Button
          type="submit"
          theme="blue"
          text={t('auth-login:form:drawers:recover-password:save-password')}
          isLoading={isLoading}
        />
      </footer>
    </form>
  );
};
export default InsertPassword;
