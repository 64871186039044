import { callAPI, mainAPIURL } from 'api/main';

import { middlewareSupportCategory } from '../middleware';

export default async (): Promise<ISupportCategory[]> => {
  try {
    const res = await callAPI({
      disableLog: true,
      callId: 'getSupportCategories',
      path: `${mainAPIURL}/v2/support/get-subjects`,
      method: 'GET',
    });

    if (!res.error && res.request && res.request.length > 0) {
      const categories = res.request.filter((o: any) => o.Id && o.Status === 'ACTIVE');

      return categories.map(middlewareSupportCategory);
    }

    return [];
  } catch (ex) {
    return [];
  }
};
