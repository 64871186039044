import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;

  img {
    width: 170px;
    max-width: 100%;
    height: auto;
    margin: 0rem auto;
  }
`;
