import { useMemo } from 'react';

import { useTranslation } from 'next-i18next';

import Drawer from 'components/atom/Drawer';

import { DefaultContainer } from '../_common/styles';
import { useFormController } from '../../useFormController';
import StepCode from './Steps/Code';
import StepPassword from './Steps/Password';
import StepSelection from './Steps/Selection';
import { useAccessController, UseAccessControllerProvider } from './useAccessController';

const Access: React.FC = () => {
  const { t } = useTranslation();
  const { actualDrawerOpen, setDrawerOpen } = useFormController();
  const { actualStep } = useAccessController();

  const StepComponent = useMemo(() => {
    const possibilities = {
      code: StepCode,
      selection: StepSelection,
      password: StepPassword,
    };

    return possibilities[actualStep];
  }, [actualStep]);

  return (
    <Drawer
      isOpen={actualDrawerOpen === 'access'}
      onClose={() => setDrawerOpen(null)}
      size="full"
      title={t('auth-login:form:drawers:access:title')}
      placement="top"
    >
      <DefaultContainer>
        <StepComponent />
      </DefaultContainer>
    </Drawer>
  );
};

const AccessWrapper: React.FC = () => (
  <UseAccessControllerProvider>
    <Access />
  </UseAccessControllerProvider>
);
export default AccessWrapper;
