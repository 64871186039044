import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'next-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { useUser } from 'hooks/useUser';

import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';

import { IFormPasswordProps } from '../../types';
import { useAccessController } from '../../useAccessController';
import schema from './schema';

const Password: React.FC = () => {
  const { t } = useTranslation();
  const { loadingAction } = useUser();

  const { handlePasswordSubmit, setActualStep } = useAccessController();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormPasswordProps>({
    resolver: yupResolver(schema()),
  });

  return (
    <form onSubmit={handleSubmit((data) => handlePasswordSubmit(data.password))}>
      <section>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('auth-login:form:drawers:access:insert-password')}
              type="password"
              error={errors.password?.message}
            />
          )}
        />

        {/* <button
          type="button"
          onClick={() => setRecoverPasswordOpen(true)}
          className="link"
          aria-label={t('auth-login:form:drawers:access:forgot-password')}
        >
          {t('auth-login:form:drawers:access:forgot-password')}
        </button> */}
      </section>

      <footer>
        <Button
          type="submit"
          theme="blue"
          text={t('common:proceed')}
          isLoading={loadingAction === 'signin'}
        />

        <Button
          type="button"
          theme="medium"
          onClick={() => setActualStep('selection')}
          text={t('common:go-back')}
        />
      </footer>
    </form>
  );
};
export default Password;
