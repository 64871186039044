import { callAPI, internalAPIURL } from 'api/main';

// ! Não será usado nessa versão

interface ISetNewPasswordProps {
  code: string;
  password: string;
}

export default async ({ code, password }: ISetNewPasswordProps): Promise<IAPIReturn> => {
  try {
    return await callAPI({
      callId: 'setNewPassword',
      path: `${internalAPIURL}/mock/generic-success`, // ! mock
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      params: {
        code,
        password,
      },
    });
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
