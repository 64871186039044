import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'next-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';

import getSupportCategories from 'api/misc/getSupportCategories';

import Drawer from 'components/atom/Drawer';
import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';
import Select from 'components/atom/Form/Select';
import Textarea from 'components/atom/Form/Textarea';
import Loader from 'components/atom/Loader';

import { DefaultContainer } from '../_common/styles';
import { IHelpFormFields } from '../../types';
import { useFormController } from '../../useFormController';
import schema from './schema';

const Help: React.FC = () => {
  const { t } = useTranslation();
  const { actualDrawerOpen, setDrawerOpen, handleHelpSubmit, loadingState } = useFormController();

  const { data: supportCategoriesDate, isLoading } = useQuery({
    queryKey: ['support-subjects'],
    queryFn: getSupportCategories,
  });

  const supportCategories = useMemo(() => supportCategoriesDate || [], [supportCategoriesDate]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IHelpFormFields>({
    resolver: yupResolver(schema()),
  });

  return (
    <Drawer
      isOpen={actualDrawerOpen === 'help'}
      onClose={() => setDrawerOpen(null)}
      size="full"
      title={t('auth-login:form:drawers:help:title')}
      placement="top"
    >
      <DefaultContainer>
        <form onSubmit={handleSubmit((data) => handleHelpSubmit(data))}>
          <section>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} label={`${t('common:name')}:`} error={errors.name?.message} />
              )}
            />

            <Controller
              name="identification"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('auth-login:form:drawers:help:identification')}
                  error={errors.identification?.message}
                />
              )}
            />

            {isLoading ? (
              <Loader />
            ) : (
              <Select
                id="subjectId"
                label={t('auth-login:form:drawers:help:subject')}
                options={supportCategories.map((category) => ({
                  value: category.id,
                  label: category.label,
                }))}
                onChange={(newValue) => setValue('subjectId', newValue, { shouldValidate: true })}
                value={getValues('subjectId')}
                error={errors.subjectId?.message || undefined}
              />
            )}

            <Controller
              name="message"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  label={`${t('common:message')}:`}
                  error={errors.message?.message}
                />
              )}
            />
          </section>

          <footer>
            <Button
              type="submit"
              theme="blue"
              text={t('auth-login:form:drawers:help:send-message')}
              isLoading={loadingState === 'help'}
            />
          </footer>
        </form>
      </DefaultContainer>
    </Drawer>
  );
};
export default Help;
