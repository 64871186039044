import { v4 as uuid } from 'uuid';

import { callAPI, mainAPIURL } from 'api/main';

interface IAskForLoginCodeProps {
  method: 'EMAIL' | 'SMS';
  personId: string;
}

export default async ({ method, personId }: IAskForLoginCodeProps): Promise<IAPIReturn> => {
  try {
    return await callAPI({
      callId: 'askForLoginCode',
      path: `${mainAPIURL}/v2/authentication/send-code`,
      method: 'POST',
      params: {
        PersonId: personId,
        Method: method,
        Device: uuid(),
      },
    });
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
