import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'next-i18next';

import { useAlerts } from 'hooks/useAlerts';
import { useUser } from 'hooks/useUser';

import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';
import ProgressCircle from 'components/atom/ProgressCircle';

import { useAccessController } from '../../useAccessController';
import { Container, TimerContainer } from './styles';

const Code: React.FC = () => {
  const { t } = useTranslation();
  const { errorMessage } = useAlerts();
  const { setActualStep, handleCodeSubmit, actualStep, handleSelectionSubmit, method } =
    useAccessController();
  const { loadingAction } = useUser();

  const [code, setCode] = useState<string>('');

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const maxTime = 15;

  const timerRef = useRef<NodeJS.Timer | null>(null);
  const [timer, setTimer] = useState<number>(maxTime);

  const handleTimer = useCallback(() => {
    setTimer((oldTimer) => {
      if (oldTimer === 0) {
        if (timerRef.current) clearInterval(timerRef.current as any); // TODO any
        return 0;
      }

      return oldTimer - 1;
    });
  }, []);

  const starTimer = useCallback(async () => {
    if (timerRef.current) clearInterval(timerRef.current as any);
    setTimer(maxTime);
    timerRef.current = setInterval(handleTimer, 1000);
  }, [handleTimer]);

  const handleStartTimer = useCallback(async () => {
    await handleSelectionSubmit();
    starTimer();
  }, [handleSelectionSubmit, starTimer]);

  useEffect(() => {
    if (actualStep === 'code' && method) starTimer();
  }, [actualStep, method, starTimer]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidateCode = useCallback(() => {
    if (code === '')
      return errorMessage(t('auth-login:form:drawers:recover-password:error-code-required'));
    return handleCodeSubmit(code);
  }, [code, errorMessage, handleCodeSubmit, t]);

  return (
    <Container>
      <form>
        <p>{t('auth-login:form:drawers:recover-password:description-step-2')}</p>

        <Input
          name="code"
          placeholder={t('common:code')}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <TimerContainer>
          <button
            type="button"
            className="link"
            onClick={() => handleStartTimer()}
            disabled={timer > 0}
          >
            {t('auth-login:form:drawers:recover-password:didnt-received-code')}
          </button>

          <ProgressCircle percentage={Math.floor((timer * 100) / maxTime)} />
        </TimerContainer>

        <footer>
          <Button
            type="button"
            theme="blue"
            onClick={handleValidateCode}
            text={t('common:proceed')}
            isLoading={loadingAction === 'signin'}
          />

          <Button
            type="button"
            theme="medium"
            onClick={() => setActualStep('selection')}
            text={t('common:go-back')}
          />
        </footer>
      </form>
    </Container>
  );
};
export default Code;
