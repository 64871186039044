import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin-bottom: 1rem;
  }

  button:disabled {
    opacity: 0.5;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;

  button {
    margin: 0;
    text-align: left;
  }
`;
