import { findIndex } from 'lodash';

import {
  IGetValueFromOptionsProps,
  IGetValueFromGroupedOptionsProps,
  ISelectOptionType,
} from './types';

export const getValueFromOptions = ({
  value,
  options,
  placeholder,
}: IGetValueFromOptionsProps): ISelectOptionType => {
  if (!value || !options) return { value: '', label: placeholder };

  if (options.length === 0) {
    return {
      value,
      label: value,
    };
  }

  try {
    const index = findIndex(options, { value });

    if (index || index === 0) {
      return {
        value,
        label: options[index].label,
      };
    }
    return {
      value,
      label: value,
    };
  } catch (error) {
    return {
      value,
      label: value,
    };
  }
};

export const getValueFromGroupedOptions = ({
  value,
  groupedOptions,
  placeholder,
}: IGetValueFromGroupedOptionsProps): ISelectOptionType => {
  if (!value || !groupedOptions) return { value: '', label: placeholder };

  if (groupedOptions.length === 0) {
    return {
      value,
      label: value,
    };
  }

  try {
    let result = {
      value,
      label: value,
    };

    let hasFound = false;

    groupedOptions.map((group) => {
      if (hasFound) return group;

      const index = findIndex(group.options, { value });
      if (index || index === 0) {
        hasFound = true;
        result = {
          value,
          label: group.options[index].label,
        };
      }

      return group;
    });

    return result;
  } catch (error) {
    return {
      value,
      label: value,
    };
  }
};
