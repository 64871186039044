import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { DrawerStylesContainer } from './styles';

interface IDrawerElementProps extends DrawerProps {
  title?: string;
  children: React.ReactNode;
}

const DrawerElement: React.FC<IDrawerElementProps> = (props) => {
  const { title, children } = props;

  return (
    <Drawer {...props}>
      <DrawerStylesContainer>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{title && <h2>{title}</h2>}</DrawerHeader>

          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </DrawerStylesContainer>
    </Drawer>
  );
};

export default DrawerElement;
