import { i18n } from 'next-i18next';

import { cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';

const schema = (): yup.AnyObjectSchema => {
  const defaultErrorMessage = i18n?.t('common:validations:errors:required-field');

  return yup.object({
    document: yup
      .string()
      .required(defaultErrorMessage)
      .test({
        message: i18n?.t('common:validations:errors:document-invalid'),
        test: (value) => (value ? cpf.isValid(value) : false),
      }),
  });
};

export default schema;
