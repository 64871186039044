import styled from 'styled-components';

export const DefaultContainer = styled.div`
  height: 100%;

  background-color: ${({ theme }) => theme.colors.white};

  .custom-textarea-container,
  .custom-select-container,
  .custom-input-container {
    span {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .link {
    background-color: transparent;
    border-width: 0;
    color: ${({ theme }) => theme.colors.dark.color};
    text-decoration: underline;
  }

  form {
    height: 100%;

    display: flex;
    flex-direction: column;

    .button {
      width: 100%;
    }

    & > section {
      padding: 0 0 2rem 0;
    }

    & > footer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      padding-bottom: 5rem;
    }
  }

  .chakra-checkbox {
    gap: 1rem;

    .chakra-checkbox__label {
      button {
        display: inline-block;
        background: transparent;
        border-width: 0;
        text-decoration: underline;
        padding: 0;
      }
    }
  }
`;
