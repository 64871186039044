import { i18n } from 'next-i18next';

import * as yup from 'yup';

const schema = (): yup.AnyObjectSchema => {
  const defaultErrorMessage = i18n?.t('common:validations:errors:required-field');

  return yup.object({
    password: yup
      .string()
      .required(defaultErrorMessage)
      .test({
        message: `${i18n?.t('common:validations:errors:password-must-be-at-least')} "${i18n?.t(
          'common:good',
        )}!"`,
        test() {
          if (!this.parent.passwordStrength) return false;
          return this.parent.passwordStrength >= 2;
        },
      }),
    passwordConfirm: yup
      .string()
      .required(defaultErrorMessage)
      .test({
        message: `${i18n?.t('common:validations:errors:passwords-not-match')}"`,
        test() {
          if (this.parent.password !== this.parent.passwordConfirm) return false;
          return true;
        },
      }),
  });
};

export default schema;
