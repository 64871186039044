import { useTranslation } from 'next-i18next';

import Button from 'components/atom/Form/Button';
import Checkbox from 'components/atom/Form/Checkbox';
import Loader from 'components/atom/Loader';

import { useRecoverPasswordController } from '../../useRecoverPasswordController';
import { Container } from './styles';

const AskForCode: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, recoveryMethod, setRecoveryMethod, askForRecoveryCode } =
    useRecoverPasswordController();

  if (isLoading || !data) return <Loader />;

  return (
    <Container>
      <p>{t('auth-login:form:drawers:recover-password:description-step-1')}</p>

      <blockquote>
        <div>
          <Checkbox
            isChecked={recoveryMethod === 'EMAIL'}
            onChange={() => setRecoveryMethod('EMAIL')}
          >
            {data.email}
          </Checkbox>
        </div>

        <hr />

        <div>
          <Checkbox isChecked={recoveryMethod === 'SMS'} onChange={() => setRecoveryMethod('SMS')}>
            {data.phone}
          </Checkbox>
        </div>
      </blockquote>

      <footer>
        <Button
          type="button"
          theme="blue"
          onClick={askForRecoveryCode}
          text={t('auth-login:form:drawers:recover-password:send-code')}
        />
      </footer>
    </Container>
  );
};
export default AskForCode;
