import styled from 'styled-components';

interface IContainerType {
  $color?: string;
  $error?: boolean;
}

export const Container = styled.div<IContainerType>`
  cursor: pointer;
  margin-bottom: 0.9rem;

  .custom-select__indicators {
    background: transparent;
    svg {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  .custom-select__indicator-separator {
    display: none !important;
  }

  .custom-select__control {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid
      ${({ $error, theme }) => ($error ? theme.colors.danger.color : theme.colors.border)};
    box-sizing: border-box;
    box-shadow: inset -3px 3px 0px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    font-size: 1rem;
    padding: 0.55rem 0.4rem;
  }

  .custom-select__control,
  .custom-select__option {
    cursor: pointer;
  }

  .custom-select__control--is-focused {
    box-shadow: none;
    border-color: hsl(0, 0%, 80%);
  }

  .custom-select__single-value,
  .custom-select__value-container,
  .custom-select__option {
    background: ${({ theme }) => theme.colors.white};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.paragraph};
  }

  .custom-select__option--is-selected {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.blue} !important;
  }

  .custom-select__option:active {
    background: ${({ theme }) => theme.colors.medium.color} !important;
    color: ${({ theme }) => theme.colors.paragraph} !important;
  }

  .error {
    margin-top: 0;
  }

  .custom-select__multi-value {
    background: ${({ theme }) => theme.colors.blue};

    div {
      color: ${({ theme }) => theme.colors.white};
      font-size: 0.8rem;
      line-height: 1.2;
    }

    div[role='button'] {
      &:hover {
        background-color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;
