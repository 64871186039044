import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'next-i18next';

import { useMutation, useQuery } from '@tanstack/react-query';

import { useAlerts } from 'hooks/useAlerts';
import { useUser } from 'hooks/useUser';

import askForRecoveryCode from 'api/user/askForRecoveryCode';
import { getUserPublicData } from 'api/user/getUserPublicData';
import setNewPassword from 'api/user/setNewPassword';

import { useFormController } from '../../useFormController';
import { IUseRecoverPasswordControllerContextData, RecoverySteps, RecoveryMethods } from './types';

const useRecoverPasswordControllerContext = createContext<IUseRecoverPasswordControllerContextData>(
  {} as IUseRecoverPasswordControllerContextData,
);
const UseRecoverPasswordControllerProvider: React.FC<IFCHasChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { errorMessage, successMessage } = useAlerts();
  const { actualDrawerOpen, setDrawerOpen, userDocument } = useFormController();
  const { signIn } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actualStep, setActualStep] = useState<RecoverySteps>('ask-code');

  const [recoveryMethod, setRecoveryMethod] = useState<RecoveryMethods>('EMAIL');
  const [recoveryCode, setRecoveryCode] = useState<string>('');

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const { data, isLoading: isLoadingData } = useQuery({
    queryKey: ['recovery-data', userDocument],
    queryFn: () => getUserPublicData({ document: userDocument }),
    enabled: !!userDocument,
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const resetDrawer = useCallback(() => {
    setRecoveryCode('');
    setActualStep('ask-code');
  }, []);

  useEffect(() => {
    if (actualDrawerOpen === 'access') resetDrawer();
  }, [actualDrawerOpen, resetDrawer]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const askForRecoveryCodeMutation = useMutation({ mutationFn: askForRecoveryCode });

  const handleAskForRecoveryCode = useCallback(async () => {
    if (askForRecoveryCodeMutation.isPending) return;

    const res = await askForRecoveryCodeMutation.mutateAsync({ method: recoveryMethod });

    if (res.error)
      return errorMessage(t('auth-login:form:drawers:recover-password:error-asking-code'));

    setActualStep('insert-code');
  }, [askForRecoveryCodeMutation, errorMessage, recoveryMethod, t]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const validateCode = useCallback((code: string) => {
    setRecoveryCode(code);
    setActualStep('insert-password');
  }, []);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setNewPasswordMutation = useMutation({ mutationFn: setNewPassword });

  const defineNewPassword = useCallback(
    async (password: string) => {
      if (setNewPasswordMutation.isPending) return;

      const res = await setNewPasswordMutation.mutateAsync({
        code: recoveryCode,
        password,
      });

      if (res.error)
        return errorMessage(t('auth-login:form:drawers:recover-password:error-setting-password'));

      setDrawerOpen(null);

      successMessage(t('auth-login:form:drawers:recover-password:success'));

      return signIn({
        data: {
          method: 'password',
          login: userDocument,
          password,
        },
      });
    },
    [
      errorMessage,
      recoveryCode,
      setDrawerOpen,
      setNewPasswordMutation,
      signIn,
      successMessage,
      t,
      userDocument,
    ],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const contextValue = useMemo(
    () => ({
      data,
      isLoading:
        isLoading ||
        isLoadingData ||
        setNewPasswordMutation.isPending ||
        askForRecoveryCodeMutation.isPending,
      setIsLoading,

      actualStep,
      setActualStep,

      recoveryMethod,
      setRecoveryMethod,

      askForRecoveryCode: handleAskForRecoveryCode,

      validateCode,

      defineNewPassword,
    }),
    [
      actualStep,
      askForRecoveryCodeMutation.isPending,
      data,
      defineNewPassword,
      handleAskForRecoveryCode,
      isLoading,
      isLoadingData,
      recoveryMethod,
      setNewPasswordMutation.isPending,
      validateCode,
    ],
  );
  return (
    <useRecoverPasswordControllerContext.Provider value={contextValue}>
      {children}
    </useRecoverPasswordControllerContext.Provider>
  );
};
const useRecoverPasswordController = (): IUseRecoverPasswordControllerContextData => {
  const context = useContext(useRecoverPasswordControllerContext);
  if (!context)
    throw new Error(
      'useuseRecoverPasswordController must be used within an UseRecoverPasswordControllerProvider',
    );
  return context;
};
export { UseRecoverPasswordControllerProvider, useRecoverPasswordController };
