import { i18n } from 'next-i18next';

import { parsePhoneNumber } from 'libphonenumber-js';

import { callAPI, mainAPIURL } from 'api/main';

interface IRegisterUserProps {
  document: string;
  name: string;
  email: string;
  cellphone: string;
  inviteCode: string;
  // password: string;
}

export default async ({
  cellphone,
  name,
  email,
  document,
}: IRegisterUserProps): Promise<IAPIReturn> => {
  try {
    const res = await callAPI({
      callId: 'registerUser',
      path: `${mainAPIURL}/v2/person/add`,
      method: 'POST',
      params: {
        Type: 'PF',
        Name: name,
        Document: document,
        Email: email,
        Phone: parsePhoneNumber(cellphone, 'BR').format('E.164'),
      },
    });

    // TODO vincular pessoa automaticamente
    // ! inviteCode

    if (res.errorCode === 'PERSON.ALREADY-EXISTS') {
      return {
        error: 'server',
        errorMessage: i18n?.t('auth-login:form:drawers:registration:user-already-registered'),
        request: null,
      };
    }

    if (!res.error) return res;

    return {
      error: 'server',
      request: null,
    };
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
