import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > header {
    flex: 0.2;
    padding: 1rem;
  }

  & > section {
    flex: 0.5;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 3rem 1rem;

    & > form {
      width: 100%;

      button {
        width: 100%;
      }

      footer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
`;

export const Footer = styled.main.attrs<IWebp>(({ $isWebpEnabled }) => ({
  style: {
    backgroundImage: $isWebpEnabled
      ? `url('/images/intro/webp/triangle-pattern.webp')`
      : `url('/images/intro/triangle-pattern.png')`,
  },
}))<IWebp>`
  flex: 0.3;

  background-color: ${({ theme }) => theme.colors.light.color};
  background-position: top;
  background-repeat: repeat-x;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  padding: 2rem 1rem;

  .img {
    padding: 2rem 1rem;
  }

  button {
    width: 100%;
    padding: 1rem 0;
    background-color: transparent;
    border-width: 0;
    color: ${({ theme }) => theme.colors.dark.color};
  }
`;
