import { Container } from './styles';

type ComponentProps = {
  percentage: number;
};

const ProgressCircle: React.FC<ComponentProps> = ({ percentage }) => (
  <Container percentage={percentage} className="progress-circle">
    <div>
      <div />
    </div>
  </Container>
);
export default ProgressCircle;
