import { callAPI, mainAPIURL } from 'api/main';

interface IGetUserPublicDataProps {
  document: string;
}

export interface IGetUserPublicDataResponse {
  personId: string;
  email: string;
  phone: string;
}

export const getUserPublicData = async ({
  document,
}: IGetUserPublicDataProps): Promise<IGetUserPublicDataResponse | null> => {
  try {
    const res = await callAPI({
      callId: 'getUserPublicData',
      path: `${mainAPIURL}/v2/person/check-exists`,
      method: 'POST',
      params: {
        Document: document,
      },
    });

    if (res && res.request) {
      return {
        personId: res.request.Id,
        email: res.request.Email,
        phone: res.request.Phone,
      };
    }

    return null;
  } catch (ex) {
    return null;
  }
};
