import { useMemo } from 'react';
import Select from 'react-select';

import Error from 'components/atom/Form/ErrorMessage';
import { Label } from 'components/atom/Form/Input/styles';

import { getValueFromOptions, getValueFromGroupedOptions } from './functions';
import { Container } from './styles';
import { ISelectType } from './types';

const MySelect: React.FC<ISelectType> = ({
  id,
  value,
  options,
  groupedOptions,
  placeholder = '',
  onChange,
  color,
  canSearch = true,
  label,
  error,
  menuPlacement = 'auto',
  ...rest
}) => {
  const processedValue = useMemo(() => {
    if (options) return getValueFromOptions({ value, options, placeholder });
    if (groupedOptions) return getValueFromGroupedOptions({ value, groupedOptions, placeholder });
    return { label: '', value: '' };
  }, [groupedOptions, options, placeholder, value]);

  return (
    <Container $color={color} $error={!!error} className="custom-select-container">
      {label && <Label>{label}</Label>}

      <Select
        id={id}
        instanceId={id}
        value={processedValue}
        options={options || groupedOptions}
        onChange={(e: any) => onChange(e.value)}
        classNamePrefix="custom-select"
        noOptionsMessage={() => 'Nada encontrado.'}
        isSearchable={canSearch}
        menuPlacement={menuPlacement}
        {...rest}
      />

      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default MySelect;
