import { useMemo } from 'react';

import { useTranslation } from 'next-i18next';

import { useQuery } from '@tanstack/react-query';

import { getUserPublicData } from 'api/user/getUserPublicData';

import Button from 'components/atom/Form/Button';
import Checkbox from 'components/atom/Form/Checkbox';
import Loader from 'components/atom/Loader';

import { useFormController } from '../../../../useFormController';
import { useAccessController } from '../../useAccessController';
import { Container } from './styles';

const Selection: React.FC = () => {
  const { t } = useTranslation();
  const { userDocument } = useFormController();
  const {
    handleSelectionSubmit,
    method,
    setMethod,
    isLoading: isLoadingAccess,
    setActualStep,
  } = useAccessController();

  const { data, isLoading } = useQuery({
    queryKey: ['recovery-data', userDocument],
    queryFn: () => getUserPublicData({ document: userDocument }),
    enabled: !!userDocument,
  });

  const showHR = useMemo(() => {
    if (data?.email && data?.phone) return true;
    return false;
  }, [data?.email, data?.phone]);

  if (isLoading || !data) return <Loader />;

  return (
    <Container>
      <section>
        <p>{t('auth-login:form:drawers:access:selection-description-1')}</p>

        <blockquote>
          {data.email && (
            <div>
              <Checkbox isChecked={method === 'EMAIL'} onChange={() => setMethod('EMAIL')}>
                {data.email}
              </Checkbox>
            </div>
          )}

          {showHR && <hr />}

          {data.phone && (
            <div>
              <Checkbox isChecked={method === 'SMS'} onChange={() => setMethod('SMS')}>
                {data.phone}
              </Checkbox>
            </div>
          )}
        </blockquote>

        <p>{t('auth-login:form:drawers:access:selection-description-2')}</p>
      </section>

      <footer>
        <Button
          type="button"
          theme="blue"
          onClick={() => handleSelectionSubmit()}
          disabled={!method}
          isLoading={isLoadingAccess}
          text={t('auth-login:form:drawers:access:send-code')}
        />

        <Button
          type="button"
          theme="medium"
          onClick={() => setActualStep('password')}
          text={t('auth-login:form:drawers:access:access-with-password')}
        />
      </footer>
    </Container>
  );
};
export default Selection;
