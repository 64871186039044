import { useTranslation } from 'next-i18next';

import { useQuery } from '@tanstack/react-query';
import templateLiteralToHTML from 'utils/common/templateLiteralToHTML';

import getTerms from 'api/misc/getTerms';

import Drawer from 'components/atom/Drawer';
import Loader from 'components/atom/Loader';

import { DefaultContainer } from '../_common/styles';
import { useFormController } from '../../useFormController';
import { Container } from './styles';

const Terms: React.FC = () => {
  const { t } = useTranslation();
  const { isTermsOpen, setTermsOpen } = useFormController();

  const { data, isLoading } = useQuery({ queryKey: ['terms'], queryFn: getTerms });

  return (
    <Drawer
      isOpen={isTermsOpen}
      onClose={() => setTermsOpen(false)}
      size="full"
      title={t('auth-login:form:drawers:terms:title')}
      placement="top"
    >
      <DefaultContainer>
        <Container>{isLoading || !data ? <Loader /> : templateLiteralToHTML(data)}</Container>
      </DefaultContainer>
    </Drawer>
  );
};
export default Terms;
