import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'next-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { useWebp } from 'hooks/useWebp';

import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';
import Logo from 'components/atom/Logo';

import DrawerAccess from './Drawers/Access';
import DrawerHelp from './Drawers/Help';
import DrawerRecoverPassword from './Drawers/RecoverPassword';
import DrawerRegistration from './Drawers/Registration';
import DrawerTerms from './Drawers/Terms';
import schema from './schema';
import { Container, Footer } from './styles';
import { IDocumentFormProps } from './types';
import { useFormController, UseFormControllerProvider } from './useFormController';

const Form: React.FC = () => {
  const { t } = useTranslation();
  const webp = useWebp();
  const {
    setDrawerOpen,
    handleDocumentSubmit,
    setTermsOpen,
    loadingState,
    actualDrawerOpen,
    isTermsOpen,
    isRecoverPasswordOpen,
  } = useFormController();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IDocumentFormProps>({
    resolver: yupResolver(schema()),
    defaultValues: {
      document: '',
    },
  });

  return (
    <>
      <Container>
        <header>
          <Logo version="square" />
        </header>

        <section>
          <form onSubmit={handleSubmit((data) => handleDocumentSubmit(data))}>
            <Controller
              name="document"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('auth-login:form:insert-document')}
                  mask="cpf"
                  inputMode="decimal"
                  error={errors.document?.message}
                />
              )}
            />

            <footer>
              <Button
                type="submit"
                theme="orange"
                text={t('common:access')}
                isLoading={loadingState === 'checking-user'}
              />
            </footer>
          </form>
        </section>

        <Footer $isWebpEnabled={webp.enabled}>
          <button
            type="button"
            onClick={() => setDrawerOpen('help')}
            aria-label={t('common:help-and-support')}
          >
            {t('common:help-and-support')}
          </button>

          <button
            type="button"
            onClick={() => setTermsOpen(true)}
            aria-label={t('common:terms-of-usage-and-privacy')}
          >
            {t('common:terms-of-usage-and-privacy')}
          </button>

          <Logo version="sao-lourenco-mono" />
        </Footer>
      </Container>

      {actualDrawerOpen === 'access' && <DrawerAccess />}
      {isRecoverPasswordOpen && <DrawerRecoverPassword />}
      {actualDrawerOpen === 'registration' && <DrawerRegistration />}
      {actualDrawerOpen === 'help' && <DrawerHelp />}
      {isTermsOpen && <DrawerTerms />}
    </>
  );
};

const FormWrapper: React.FC = () => (
  <UseFormControllerProvider>
    <Form />
  </UseFormControllerProvider>
);
export default FormWrapper;
