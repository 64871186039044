import config from 'config/seo';

import Image from 'components/atom/Image';

import { Container } from './styles';

type LogoType = {
  version?:
    | 'default'
    | 'mono'
    | 'square'
    | 'square-blue'
    | 'arraial'
    | 'sao-lourenco'
    | 'sao-lourenco-mono';
};

const logos = {
  default: {
    width: 208,
    height: 152,
  },
  mono: {
    width: 232,
    height: 91,
  },
  square: {
    width: 208,
    height: 152,
  },
  'square-blue': {
    width: 330,
    height: 210,
  },
  arraial: {
    width: 398,
    height: 134,
  },
  'sao-lourenco': {
    width: 470,
    height: 74,
  },
  'sao-lourenco-mono': {
    width: 353,
    height: 56,
  },
};

const Logo: React.FC<LogoType> = ({ version = 'default' }) => (
  <Container className="logo-site">
    <Image
      src={`/images/logo/${version}.png`}
      alt={String(config().title)}
      width={logos[version].width}
      height={logos[version].height}
    />
  </Container>
);

export default Logo;
