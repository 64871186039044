import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useTranslation } from 'next-i18next';

import { useMutation } from '@tanstack/react-query';

import { useAlerts } from 'hooks/useAlerts';
import { useUser } from 'hooks/useUser';
import { SignInData } from 'hooks/useUser/types/reducer';

import sendSupportMessage from 'api/misc/sendSupportMessage';
import checkIfUserExists from 'api/user/checkIfUserExists';
import registerUser from 'api/user/registerUser';

import {
  IUseFormControllerContextData,
  AvailableDrawers,
  IHelpFormFields,
  LoadingState,
  IRegistrationFormFields,
  IDocumentFormProps,
  IAccessSubmitProps,
} from './types';

const useFormControllerContext = createContext<IUseFormControllerContextData>(
  {} as IUseFormControllerContextData,
);
const UseFormControllerProvider: React.FC<IFCHasChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { errorMessage, successMessage } = useAlerts();
  const [loadingState, setLoadingState] = useState<LoadingState>(null);

  const [actualDrawerOpen, setDrawerOpen] = useState<AvailableDrawers>(null);
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isRecoverPasswordOpen, setRecoverPasswordOpen] = useState(false);

  const [userId, setUserId] = useState<string>('');
  const [userDocument, setUserDocument] = useState<string>('');

  const { signIn } = useUser();

  const checkIfUserExistsMutation = useMutation({ mutationFn: checkIfUserExists });

  const handleDocumentSubmit = useCallback(
    async ({ document }: IDocumentFormProps) => {
      if (checkIfUserExistsMutation.isPending) return;

      setUserDocument(document);

      setLoadingState('checking-user');
      const resUserId = await checkIfUserExistsMutation.mutateAsync({
        document,
      });

      setLoadingState(null);

      if (resUserId) {
        setDrawerOpen('access');
        return setUserId(resUserId);
      }

      // Permite que um usuário não registrado se registre com um código de convite
      // setDrawerOpen('registration');

      return errorMessage(t('auth-login:form:drawers:access:user-not-registered'));
    },
    [checkIfUserExistsMutation, errorMessage, t],
  );

  const handleAccessSubmit = useCallback(
    (props: IAccessSubmitProps) => {
      const params: SignInData =
        props.method === 'password'
          ? {
              method: 'password',
              login: userDocument,
              password: props.password,
            }
          : {
              method: 'token',
              code: props.code,
              userId,
            };

      signIn({
        data: params,
      });
    },
    [signIn, userDocument, userId],
  );

  const sendSupportMessageMutation = useMutation({ mutationFn: sendSupportMessage });

  const handleHelpSubmit = useCallback(
    async (fields: IHelpFormFields) => {
      if (sendSupportMessageMutation.isPending) return;

      setLoadingState('help');
      const res = await sendSupportMessageMutation.mutateAsync({
        ...fields,
        additional: `Identificação: ${fields.identification}`,
      });
      setLoadingState(null);

      if (res.error) return errorMessage(t('auth-login:form:drawers:help:message-error'));

      setDrawerOpen(null);
      return successMessage(t('auth-login:form:drawers:help:message-sent'));
    },
    [errorMessage, sendSupportMessageMutation, successMessage, t],
  );

  const registerUserMutation = useMutation({ mutationFn: registerUser });

  const handleRegistrationSubmit = useCallback(
    async (fields: IRegistrationFormFields) => {
      if (registerUserMutation.isPending) return;

      setLoadingState('help');
      const res = await registerUserMutation.mutateAsync({
        ...fields,
        document: userDocument,
      });
      setLoadingState(null);

      if (res.error)
        return errorMessage(res.errorMessage || t('auth-login:form:drawers:registration:error'));

      successMessage(t('auth-login:form:drawers:registration:registration-success'));
      return setDrawerOpen(null);
    },
    [errorMessage, registerUserMutation, successMessage, t, userDocument],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const contextValue = useMemo(
    () => ({
      loadingState,

      actualDrawerOpen,
      setDrawerOpen,

      userId,
      userDocument,
      setUserDocument,

      isTermsOpen,
      setTermsOpen,

      isRecoverPasswordOpen,
      setRecoverPasswordOpen,

      handleDocumentSubmit,
      handleAccessSubmit,
      handleHelpSubmit,
      handleRegistrationSubmit,
    }),
    [
      loadingState,
      actualDrawerOpen,
      userId,
      userDocument,
      isTermsOpen,
      isRecoverPasswordOpen,
      handleDocumentSubmit,
      handleAccessSubmit,
      handleHelpSubmit,
      handleRegistrationSubmit,
    ],
  );

  return (
    <useFormControllerContext.Provider value={contextValue}>
      {children}
    </useFormControllerContext.Provider>
  );
};
const useFormController = (): IUseFormControllerContextData => {
  const context = useContext(useFormControllerContext);
  if (!context)
    throw new Error('useuseFormController must be used within an UseFormControllerProvider');
  return context;
};
export { UseFormControllerProvider, useFormController };
