import { forwardRef, TextareaHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Error, Label, IconContainer, MaxCharsContainer } from './styles';

interface ICustomTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  name?: string;
  value?: string;
  error?: string;
  label?: string | null;
  icon?: any;
  height?: number;
  rows?: number;

  maxChars?: number;
  internalRef?: React.Ref<HTMLTextAreaElement>;
}

const CustomTextArea = forwardRef<HTMLTextAreaElement, ICustomTextAreaProps>((props, ref) => {
  const {
    error,
    label = null,
    icon = false,
    id,
    height,
    maxChars,
    value,
    internalRef,
    ...rest
  } = props;

  return (
    <Container $error={error} htmlFor={id} $height={height} className="custom-textarea-container">
      {label && <Label>{label}</Label>}

      {icon ? (
        <IconContainer>
          <div>
            <FontAwesomeIcon icon={icon} />
          </div>

          <textarea ref={ref} id={id} value={value} {...rest} />
        </IconContainer>
      ) : (
        <textarea ref={internalRef} id={id} value={value} {...rest} />
      )}

      {maxChars && (
        <MaxCharsContainer $reachedMaxChars={value ? value.toString().length >= maxChars : false}>
          {`${value ? value.toString().length : 0}/${maxChars}`}
        </MaxCharsContainer>
      )}

      {error && <Error>{error}</Error>}
    </Container>
  );
});

export default CustomTextArea;
