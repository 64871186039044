import { i18n } from 'next-i18next';

import * as yup from 'yup';

const schema = (): yup.AnyObjectSchema => {
  const defaultErrorMessage = i18n?.t('common:validations:errors:required-field');

  return yup.object({
    name: yup.string().required(defaultErrorMessage),
    identification: yup.string().required(defaultErrorMessage),
    subjectId: yup.string().required(defaultErrorMessage),
    message: yup.string().required(defaultErrorMessage),
  });
};

export default schema;
