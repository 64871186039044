import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// import PasswordStrengthBar from 'react-password-strength-bar';

import { useTranslation } from 'next-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import Drawer from 'components/atom/Drawer';
import Button from 'components/atom/Form/Button';
import Checkbox from 'components/atom/Form/Checkbox';
import Input from 'components/atom/Form/Input';

import { DefaultContainer } from '../_common/styles';
import { IRegistrationFormFields } from '../../types';
import { useFormController } from '../../useFormController';
import schema from './schema';
import { Container } from './styles';

const Registration: React.FC = () => {
  const { t } = useTranslation();
  const { actualDrawerOpen, setDrawerOpen, handleRegistrationSubmit, loadingState, setTermsOpen } =
    useFormController();

  const [hasAcceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    // watch,
    // setValue,
    formState: { errors },
  } = useForm<IRegistrationFormFields>({
    resolver: yupResolver(schema()),
    defaultValues: {
      cellphone: '55',
    },
  });

  /* const watchPassword = watch('password');

  const passwordStrengthStrings = useMemo(
    () => [
      t('common:bad'),
      t('common:weak'),
      t('common:good'),
      t('common:great'),
      t('common:excellent'),
    ],
    [t]
  ); */

  return (
    <Drawer
      isOpen={actualDrawerOpen === 'registration'}
      onClose={() => setDrawerOpen(null)}
      size="full"
      title={t('auth-login:form:drawers:registration:title')}
      placement="top"
    >
      <Container>
        <DefaultContainer>
          <form onSubmit={handleSubmit((data) => handleRegistrationSubmit(data))}>
            <section>
              <Controller
                name="inviteCode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={`${t('auth-login:form:drawers:registration:invite-code')}:`}
                    error={errors.inviteCode?.message}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} label={`${t('common:name')}:`} error={errors.name?.message} />
                )}
              />

              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} label={`${t('common:email')}:`} error={errors.email?.message} />
                )}
              />

              <Controller
                name="cellphone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    mask="cellphoneWithCountryCode"
                    label={`${t('common:cellphone')}:`}
                    inputMode="decimal"
                    error={errors.cellphone?.message}
                  />
                )}
              />

              {/* <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    label={t('auth-login:form:drawers:registration:choose-password')}
                    error={errors.password?.message}
                  />
                )}
              />
              <PasswordStrengthBar
                password={watchPassword}
                scoreWords={passwordStrengthStrings}
                shortScoreWord={t('common:too-short')}
                onChangeScore={(score) =>
                  setValue('passwordStrength', score, { shouldValidate: true })
                }
                className="password-strength-bar"
              />

              <Controller
                name="passwordConfirm"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    label={t('auth-login:form:drawers:registration:confirm-password')}
                    error={errors.passwordConfirm?.message}
                  />
                )}
              /> */}

              <Checkbox
                isChecked={hasAcceptedTerms}
                onChange={() => setAcceptedTerms(!hasAcceptedTerms)}
              >
                <p>
                  {t('auth-login:form:drawers:registration:declare-read')}
                  <button
                    type="button"
                    onClick={() => setTermsOpen(true)}
                    aria-label={t('common:terms-of-usage-and-privacy')}
                  >
                    {t('common:terms-of-usage-and-privacy')}
                  </button>
                </p>
              </Checkbox>
            </section>

            <footer>
              <Button
                type="submit"
                theme="blue"
                text={t('auth-login:form:drawers:registration:submit')}
                isLoading={loadingState === 'help'}
                disabled={!hasAcceptedTerms}
              />
            </footer>
          </form>
        </DefaultContainer>
      </Container>
    </Drawer>
  );
};
export default Registration;
