import { callAPI, mainAPIURL } from 'api/main';

export default async (): Promise<string> => {
  try {
    const res = await callAPI({
      disableLog: true,
      callId: 'getTerms',
      path: `${mainAPIURL}/v2/generic/support-data/current-policy`,
      method: 'GET',
    });

    if (!res.error && res.request && res.request.Content) {
      return res.request.Content;
    }

    return '';
  } catch (ex) {
    return '';
  }
};
