import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import askForLoginCode from 'api/user/askForLoginCode';

import { useFormController } from '../../useFormController';
import { IUseAccessControllerContextData, StepsType, LoginType } from './types';

const useAccessControllerContext = createContext<IUseAccessControllerContextData>(
  {} as IUseAccessControllerContextData,
);
const UseAccessControllerProvider: React.FC<IFCHasChildren> = ({ children }) => {
  const { handleAccessSubmit, userId } = useFormController();

  const [method, setMethod] = useState<LoginType>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [actualStep, setActualStep] = useState<StepsType>('selection');

  const handleSelectionSubmit = useCallback(async () => {
    if (!method) return;

    setLoading(true);
    await askForLoginCode({ method, personId: userId });
    setLoading(false);

    // Não farei tratativa de erro aqui porque quero que o usuário caia na tela de input de código sempre
    // Teremos uma forma do usuário pedir ajuda de um administrador para pedir o código caso dê algo errado

    setActualStep('code');
  }, [method, userId]);

  const handleCodeSubmit = useCallback(
    (code: string) =>
      handleAccessSubmit({
        method: 'token',
        code,
      }),
    [handleAccessSubmit],
  );

  const handlePasswordSubmit = useCallback(
    (password: string) =>
      handleAccessSubmit({
        method: 'password',
        password,
      }),
    [handleAccessSubmit],
  );

  const contextValue = useMemo(
    () => ({
      actualStep,
      setActualStep,

      method,
      setMethod,

      isLoading,

      handleSelectionSubmit,
      handleCodeSubmit,
      handlePasswordSubmit,
    }),
    [actualStep, method, isLoading, handleSelectionSubmit, handleCodeSubmit, handlePasswordSubmit],
  );
  return (
    <useAccessControllerContext.Provider value={contextValue}>
      {children}
    </useAccessControllerContext.Provider>
  );
};
const useAccessController = (): IUseAccessControllerContextData => {
  const context = useContext(useAccessControllerContext);
  if (!context)
    throw new Error('useAccessController must be used within an UseAccessControllerProvider');
  return context;
};
export { UseAccessControllerProvider, useAccessController };
