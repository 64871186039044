import { Container } from './styles';

interface IPageTemplateProps {
  children: React.ReactNode;
}

const PageTemplate: React.FC<IPageTemplateProps> = ({ children }) => (
  <Container>{children}</Container>
);

export default PageTemplate;
