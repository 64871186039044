import { v4 as uuid } from 'uuid';

export const middlewareMedia = (data: any): IMedia => ({
  id: data?.id || uuid(),
  identifier: data?.Identifier || uuid(),
  url: data?.Url || '/images/lorem/default.jpg',
  mime: data?.Mime || 'image/jpg',
  class: data?.Class || '',
});

export const middlewareSupportCategory = (data: any): ISupportCategory => ({
  id: data?.Id || uuid(),
  label: data?.Description || '',
});
