import { callAPI, mainAPIURL } from 'api/main';

interface ISendSupportMessageProps {
  name: string;
  additional: string;
  message: string;
  subjectId: string;
}

export default async ({
  additional,
  message,
  name,
  subjectId,
}: ISendSupportMessageProps): Promise<IAPIReturn> => {
  try {
    return await callAPI({
      callId: 'sendSupportMessage',
      path: `${mainAPIURL}/v2/support/add`,
      method: 'POST',
      params: {
        SubjectId: subjectId,
        Name: name,
        Description: message,
        Additional: additional,
      },
    });
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
