import { callAPI, internalAPIURL } from 'api/main';

// ! Não será usado nessa versão

interface IAskForRecoveryCodeProps {
  method: 'EMAIL' | 'SMS';
}

export default async ({ method }: IAskForRecoveryCodeProps): Promise<IAPIReturn> => {
  try {
    return await callAPI({
      callId: 'askForRecoveryCode',
      path: `${internalAPIURL}/mock/generic-success`, // ! mock
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      params: {
        method,
      },
    });
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
