import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  $border?: boolean;
  $isRounded?: boolean;
}

export const Container = styled.span<IContainerProps>`
  line-height: 0.6rem;
  display: block;

  label {
    margin-right: 0.3rem;

    ${({ $border }) =>
      $border &&
      css`
        border: 1px solid ${({ theme }) => darken(0.1, theme.colors.medium.color)};
        padding: 0.4rem;
        border-radius: 3px;
      `}

    svg {
      width: 0.6rem;
    }

    .chakra-checkbox__label {
      font-size: 0.7rem;
      line-height: 1.1rem;
      color: ${({ theme }) => theme.colors.paragraph};
    }

    .chakra-checkbox__control {
      border: 1px solid
        ${({ theme, $isRounded }) => ($isRounded ? theme.colors.blue : theme.colors.dark.color)} !important;
      background: ${({ theme }) => theme.colors.white};
      outline: 0;
      box-shadow: none;
      transition: 0.3s;

      ${({ $isRounded }) =>
        $isRounded &&
        css`
          border-radius: 50%;
        `}
    }
    .chakra-checkbox__control[data-checked] {
      ${({ $isRounded }) =>
        $isRounded
          ? css`
              background: ${({ theme }) => theme.colors.blue};

              svg {
                color: ${({ theme }) => theme.colors.white};
              }
            `
          : css`
              background: ${({ theme }) => theme.colors.white};

              svg {
                color: ${({ theme }) => theme.colors.blue};
              }
            `}
    }

    div:nth-of-type(2) {
      border-width: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-size: 0.8rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.blue};
      }
      svg {
        width: 0.9rem;
        color: ${({ theme }) => theme.colors.blue};
        margin-right: 0.3rem;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }
`;
