import styled, { css } from 'styled-components';

type ContainerProps = {
  percentage: number;
};

export const Container = styled.div<ContainerProps>`
  background: conic-gradient(
    from 0deg at 50% 50%,
    ${({ theme }) => theme.colors.grayMediumLight} 0deg,
    ${({ theme }) => theme.colors.grayMediumLight} 360deg
  );

  min-width: 1.2rem;
  max-width: 1.2rem;
  width: 1.2rem;

  min-height: 1.2rem;
  max-height: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.3s;

    ${({ percentage }) => {
      const limitAngleValue = Math.floor((360 * percentage) / 100);

      return css`
        background: conic-gradient(
          from 0deg at 50% 50%,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) ${limitAngleValue}deg,
          ${({ theme }) => theme.colors.white} ${limitAngleValue}deg,
          ${({ theme }) => theme.colors.white} -360deg
        );
      `;
    }}
    &
      > div {
      width: 80%;
      height: 80%;

      display: block;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
    }
  }
`;
