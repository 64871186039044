import { Checkbox, CheckboxProps } from '@chakra-ui/react';

import { Container } from './styles';

interface ICustomCheckboxProps extends CheckboxProps {
  hasBorder?: boolean;
  isRounded?: boolean;
  children?: React.ReactNode;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  hasBorder = false,
  isRounded = false,
  children,
  ...rest
}) => (
  <Container $isRounded={isRounded} $border={hasBorder}>
    <Checkbox {...rest}>{children}</Checkbox>
  </Container>
);

export default CustomCheckbox;
