import styled from 'styled-components';

export const Container = styled.form`
  section {
    padding-top: 0 !important;
  }

  blockquote {
    background-color: ${({ theme }) => theme.colors.light.color};
    border-radius: 10px;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      padding: 0 1rem;

      .chakra-checkbox__label {
        font-family: ${({ theme }) => theme.font.title};
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;
