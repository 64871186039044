/* eslint-disable @next/next/no-img-element */
import { useCallback, useMemo, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import Image, { ImageProps } from 'next/image';

import configJson from '../../../../image-domains-allowed.json';
import { Container, NativeImageContainer } from './styles';

interface IMyImageProps extends ImageProps {
  srcWebp?: string;
  disableNextJSOptimization?: boolean;
  onErrorImageCallback?: string;
}

const MyImage: React.FC<IMyImageProps> = (props) => {
  const {
    className,
    src,
    alt,
    srcWebp,
    width = 116,
    height = 82,
    disableNextJSOptimization = true,
    onErrorImageCallback = '/images/image-not-loaded-placeholder.jpg',
  } = props;

  const [imageSrc, setImageSrc] = useState(src);

  const imageIsInDomainsAllowed = useMemo(() => {
    let allowed = false;

    configJson.domains.map((domain: string) => {
      if (String(src).includes(domain)) allowed = true;
      return domain;
    });

    return allowed;
  }, [src]);

  const handleOnImageError = useCallback(() => {
    setImageSrc(onErrorImageCallback);
  }, [onErrorImageCallback]);

  if (!imageIsInDomainsAllowed || disableNextJSOptimization) {
    return (
      <NativeImageContainer
        $width={parseInt(String(width), 10)}
        $height={parseInt(String(height), 10)}
        className={`img native ${className || ''}`}
      >
        <LazyLoadComponent>
          <picture>
            <source srcSet={`${srcWebp || imageSrc}`} type="image/webp" />
            <source srcSet={`${imageSrc}`} type="image/jpeg" />
            <img
              src={`${imageSrc}`}
              alt={alt}
              width={width}
              height={height}
              onError={handleOnImageError}
            />
          </picture>
        </LazyLoadComponent>
      </NativeImageContainer>
    );
  }

  return (
    <Container>
      <Image
        {...props}
        alt={alt || ''}
        src={imageSrc}
        width={width}
        height={height}
        className={`img ${className || ''}`}
        placeholder="blur"
      />
    </Container>
  );
};

export default MyImage;
